@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;0,800;1,400&display=swap");

html,
body,
* {
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #f5f5fb;
  padding: 20px;
}

b {
  font-weight: 700;
}

i {
  font-style: italic;
}

a {
  color: rgb(49, 83, 151);
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid rgb(49, 83, 151);
}

.image-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 60px;
  height: 100px;
  background-image: url("./logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.title {
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  font-weight: 800;
  color: rgb(49, 83, 151);
  margin: 0 0 40px 0;
}

.description {
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: rgb(40, 41, 49);
  margin-bottom: 10px;
}

.description.description-secondary {
  color: rgb(137, 137, 137);
}

.form {
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 10px;
}

.form-field {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: rgb(40, 41, 49);
}

.form-field.form-field-filler {
}

.form-field .description {
  color: rgb(137, 137, 137);
}

.form-label {
  color: rgb(40, 41, 49);
}

.form-checkbox {
}

.form-checkbox input {
  margin-right: 5px;
}

.form-input {
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  padding: 0 10px;
  color: rgb(40, 41, 49);
}

.form-input.error {
  border-color: #ff3548;
}

.form-input:disabled {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
  cursor: not-allowed;
}

.form-signature {
  width: 100%;
  height: 100px;
}

.form-error {
  margin-top: 5px;
  font-size: 12px;
  color: #ff3548;
}

.form-date {
  margin: 10px 0;
  color: rgb(40, 41, 49);
  text-align: center;
}

.form-button {
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  height: 38px;
  -webkit-transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s;
  transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s;
  border: 2px solid rgb(49, 83, 151);
  background-color: rgb(49, 83, 151);
  color: #fff;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.form-button:hover {
  background-color: rgb(40, 41, 49);
  border-color: rgb(40, 41, 49);
}

.form-button:disabled {
  background-color: #73bad8;
  border-color: #73bad8;
  cursor: not-allowed;
}

.form-success {
  margin-top: 20px;
  background-color: #bbf7d0;
  color: #16a34a;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

.form-rgpd {
  margin-top: 20px;
  color: rgb(40, 41, 49);
  text-align: center;
}

.links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.links a {
  color: rgb(49, 83, 151);
}

.checkbox-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
  cursor: pointer;
  color: rgb(40, 41, 49);
  font-size: 16px;
}

.checkbox-label .checkbox-input {
  display: none;
}

.checkbox-label .checkbox-input:checked + .checkbox-box {
  background: #2e5596;
  border-color: #2e5596;
}

.checkbox-label .checkbox-input:checked + .checkbox-box:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.checkbox-label .checkbox-box {
  background-color: white;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #e6e1e5;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: background 0.2s border-color 0.2s;
  -o-transition: background 0.2s border-color 0.2s;
  transition: background 0.2s border-color 0.2s;
  margin-bottom: 10px;
}

.checkbox-error .checkbox-label .checkbox-box {
  border-color: #ff3548;
}

.checkbox-text {
  margin: 0px 10px;
  padding: 0;
  cursor: text;
}

.checkbox-error .checkbox-label .checkbox-text,
.checkbox-error .checkbox-label .checkbox-text * {
  color: #ff3548;
}

.checkbox-label .checkbox-box:hover {
  background: #e6e1e5;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.checkbox-label .checkbox-box:after {
  content: "\2714";
  color: #fff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.list {
  list-style-position: inside;
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0 20px 60px 20px;
  }

  .image-container {
    background-image: url("./logo_vertical.png");
    height: 240px;
  }

  .form-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
  }

  .form-field.form-field-filler {
    display: none;
  }
}

.email::before {
  content: attr(data-domain) "\0040"attr(data-contact);
  unicode-bidi: bidi-override;
  direction: rtl;
  color: rgb(49, 83, 151);
  text-decoration: underline;
  cursor: pointer;
}

.phone::before {
  content: attr(data-fifth-part) "\00a0"attr(data-fourth-part) "\00a0"attr(
      data-third-part
    )
    "\00a0"attr(data-second-part) "\00a0"attr(data-first-part);
  unicode-bidi: bidi-override;
  direction: rtl;
  color: rgb(49, 83, 151);
  text-decoration: underline;
  cursor: pointer;
}
